import styles from  './styles/gift.css'

export const Gift = ()=>{
    return (
    <>
        <h1 className="gift-title" >Thank You For Helping Us Build Our Nest Egg</h1>
        <p className={"gift-title"} style={{color:'#792839', fontWeight:'600', fontSize:'1.2rem'}}>please note if you prefer to gift cash, a gift box for envelopes will be present on the day</p>
        <div className="gift">
            <iframe className="gift-frame" src="https://pay.yoco.com/callan-and-ancois-say-i-do"></iframe>
        </div>
    </>
    )
}