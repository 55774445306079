import "./globals.css";
import {LanguageProvider, useLanguage} from "./context";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Home from "./components/home";
import {Gift} from "./components/gift";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path:"/gift",
        element: <Gift/>,
    }
]);

function App() {

    return (
        <LanguageProvider>
            <RouterProvider router={router} />
        </LanguageProvider>
    );
}

export default App;
