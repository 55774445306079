import React, { createContext, useState, useContext } from "react";

// Create the context
const LanguageContext = createContext();

// Language provider component
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(""); // Default language is 'en' (English)

    // Function to switch languages
    const switchLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider value={{ language, switchLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Custom hook to use the LanguageContext
export const useLanguage = () => {
    return useContext(LanguageContext);
};
