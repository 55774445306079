import Gallery from "./gallery";
import RsvpFrom from "./rsvp-from";
import {useLanguage} from "../context";
import FlipDownComponent from "./flip-down";
import localization from "../localization";
import React, {useEffect} from "react";

const Home = () => {
    const {language, switchLanguage} = useLanguage();
    const [content, setContent] = React.useState([]);

    const disectObj = (obj) => {
        return  Object.entries(obj).map(([key, value]) => {
            if (value instanceof Object && !Array.isArray(value)) {
                return disectObj(value)
            } else {
                console.log(key, language)
                if (key === language) {
                    return value
                } else {
                    return null
                }
            }
        }).filter((x)=>x!==null);

    }

    useEffect(() => {
        if(language !=="") {
            const contentArray = disectObj(localization)
            setContent(contentArray)
        }
    }, [language]);

    useEffect(()=>{console.log(content)}, [content]);

    return (
        <>
            <h1 className="page-title">{language !== "" ? content[0] : "Callan And Ancois Say I Do In:"}</h1>
            <FlipDownComponent/>
            {!language && <div className="card language-selection">
                <h1>PLease Choose a Language Preference</h1>
                <button onClick={() => switchLanguage('english')}>English</button>
                <button onClick={() => switchLanguage('afrikaans')}>Afrikaans</button>
            </div>
            }
            {language && content.length > 0 && <>
                <Gallery/>
                <RsvpFrom content={content} />
            </>
            }
        </>
    )
}

export default Home;


