import React, { useEffect } from "react";
/* global FlipDown */

const FlipDownComponent = () => {
    useEffect(() => {
        // Get the current date and time
        const now = new Date();

        // Define the target date and time (14 December at 16:00)
        const targetDate = new Date(now.getFullYear(), 11, 14, 16, 0, 0, 0); // December is month 11 (0-based index)

        // If the target date is in the past, adjust the targetDate to the next year
        if (now > targetDate) {
            targetDate.setFullYear(targetDate.getFullYear() + 1);
        }

        // Calculate the difference in milliseconds
        const timeDifference = targetDate - now;

        // Convert milliseconds to seconds
        const secondsDifference = Math.floor(timeDifference / 1000);
        // Unix timestamp (in seconds) to count down to
        const twoDaysFromNow = new Date().getTime() / 1000 + secondsDifference;

        // Set up FlipDown
        new FlipDown(twoDaysFromNow)
            // Start the countdown
            .start()
            // Do something when the countdown ends
            .ifEnded(() => {
                console.log("The countdown has ended!");
            });

        // Toggle theme
    }, []);

    return <div id="flipdown" className="flipdown flipdown__theme-dark"></div>;
};

export default FlipDownComponent;
