import React from "react";
import "./styles/gallery.css";
import FlipDownComponent from "./flip-down";

const Gallery = () => {
    return (
        <>
            <div className="container">
                <div id="carousel">
                    {new Array(9).fill(null).map((_, index) => (
                        <figure key={index}>
                            <img
                                src={`/images/CA-000${index + 1}.jpg`}
                                alt="loading"
                            />
                        </figure>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Gallery;
