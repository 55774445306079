import React from "react";
import "./styles/rsvp-form.css";
import axios from "axios";
import {LiaSpinnerSolid} from "react-icons/lia";
import {useGoogleRecaptcha} from "google-recaptcha-v3";
import {LuMapPin} from "react-icons/lu";

export default function RsvpFrom({content}) {
    const [state, setState] = React.useState({
        dietary_restrictions: false,
        children: false,
        plus_one: false,
        how_many_children: 0,
        children_details: [],
    });

    const [submitResult, setSubmitResult] = React.useState(undefined);
    const [error, setError] = React.useState(<></>);
    const [rsvp, setRsvp] = React.useState(undefined);

    const siteKey = "6LdubT4qAAAAABuctJMx5NWWaaW8SzAq4JA6Sv8G";
    const action = "submit";
    const {token} = useGoogleRecaptcha(siteKey, action);

    const onSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm(state, content);

        if (Object.keys(errors).length > 0) {
            const message = (<> {content?.[1]?.[3]?.[0]?.[0]}<br/>{Object.values(errors).map((error) => {
                    return (<li>{error}</li>)
                }
            )}</>)

            setError(message)
        } else {

            const axiosConfig = {
                method: "post",
                baseURL: "https://backend.callanandancoissayido.co.za",
                url: "/api/v1/rsvp-form",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "recaptcha-token": token
                },
                data: state,
            };
            setSubmitResult(null);
            axios.request(axiosConfig).then((response) => {
                response.status === 200
                    ? setSubmitResult(true)
                    : setSubmitResult(false);
            });
            setError(undefined)
        }
    };

    const handleChange = (e) => {
        let {name, type, value} = e.target;
        let dataSet = e.target.dataset?.index;
        if (dataSet === undefined) {
            console.log(name);
            if (name === "how_many_children" && value < 0) {
                value = 0;
                setState((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    [name]: type === "checkbox" ? !state[name] : value,
                }));
            }
        } else {
            dataSet = parseInt(dataSet);
            setState((prevState) => {
                const updatedChildrenDetails = [...prevState.children_details];

                // Update the specific index in the copy
                updatedChildrenDetails[dataSet] = {
                    ...updatedChildrenDetails[dataSet],
                    [name]: value,
                };

                // Return the new state object
                return {
                    ...prevState,
                    children_details: updatedChildrenDetails,
                };
            });
        }
    };

    React.useEffect(() => {
        if (!state.children) {
            setState((prevState) => ({
                ...prevState,
                how_many_children: 0,
                children_details: [],
            }));
        }
    }, [state.children]);

    React.useEffect(() => {
        let stateArray = [];
        const arrayLength = state.children_details.length;
        const numberOfChildren = parseInt(state.how_many_children);
        const arrayObject = {
            childs_first_name: "",
            how_old_child: 0,
        };
        if (numberOfChildren > 0) {
            if (arrayLength === 0) {
                stateArray = new Array(numberOfChildren).fill(arrayObject);
            } else {
                const newAdditions = numberOfChildren - arrayLength;
                if (newAdditions > 0) {
                    const newArrayElements = new Array(newAdditions).fill(
                        arrayObject
                    );
                    stateArray = [
                        ...state.children_details,
                        ...newArrayElements,
                    ];
                } else {
                    stateArray = [...state.children_details];
                    stateArray.pop();
                }
            }
            setState({
                ...state,
                children_details: stateArray,
            });
        } else {
            setState((prevState) => ({
                ...prevState,
                children_details: [],
            }));
        }
    }, [state.how_many_children]);


    return (
        <div className="card">
            <form style={{width: "100%"}} onSubmit={onSubmit}>
                <h1>{content?.[1]?.[0]}</h1>
                <div className="details-wrapper">
                    <h2>
                        {content?.[1]?.[1]?.[0]}
                    </h2>
                    <ul>
                        <li><p>
                            <strong>{content?.[1]?.[1]?.[1]?.[0]?.[0]}:</strong>&nbsp;{content?.[1]?.[1]?.[1]?.[0]?.[1]}
                        </p></li>
                        <li><p>
                            <strong>{content?.[1]?.[1]?.[2]?.[0]?.[0]}:</strong>&nbsp;{content?.[1]?.[1]?.[2]?.[0]?.[1]}
                        </p></li>
                        <li><p>
                            <strong>{content?.[1]?.[1]?.[3]?.[0]?.[0]}:</strong> &nbsp;{content?.[1]?.[1]?.[3]?.[0]?.[1]}
                        </p></li>
                        <li>
                            <p><strong>{content?.[1]?.[1]?.[4]?.[0]?.[0]}&nbsp;<LuMapPin/>&nbsp;:</strong>
                                <button onClick={(e) => {
                                    e.preventDefault()
                                    window.open("https://www.google.com/maps/dir//Plot+220,+Bynest+turnoff,+Kameelfontein,+Adderweg,+Roodeplaat,+Pretoria,+0002/@-25.6351709,28.356063,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ebfe3bc246ecfe9:0xe4a9424bbda741bb!2m2!1d28.4384645!2d-25.635194?entry=ttu&g_ep=EgoyMDI0MDkwOS4wIKXMDSoASAFQAw%3D%3D", '_blank', 'noopener,noreferrer');
                                }}>{content?.[1]?.[1]?.[4]?.[0]?.[1]}</button>
                            </p>
                        </li>
                    </ul>
                </div>
                    {submitResult !== true && (
                        <>
                        {(rsvp === undefined || rsvp === false) &&
                            <div className="form-group-row">
                                <div style={{width: "100%", justifyContent: 'space-between'}}
                                     className="input-group-col">
                                    <label style={{margin: 'auto'}}>{content?.[1]?.[2]?.[0]?.[0]}</label>
                                    <div style={{justifyContent: 'space-between', margin: 'auto', width:"80%"}}
                                         className="input-group-row">
                                        <button onClick={(e)=>{e.preventDefault(); setRsvp(true)}} style={{width: '40%', height: "50px", padding: '0'}}>{content?.[2]?.[0]?.[0]}</button>
                                        <button onClick={(e)=>{e.preventDefault(); setRsvp(false)}} style={{width: '40%', height: "50px", padding: '0'}}>{content?.[2]?.[0]?.[1]}</button>
                                    </div>
                                    {rsvp===false && <p>{content?.[1]?.[4]?.[0]?.[1]}</p>}
                                </div>
                            </div>
                        }
                            {rsvp === true && <>
                                <div className="form-group-row">
                                    <div className="input-group-col">
                                        <label
                                            htmlFor="first_name"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[1]}
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="input-group-col">
                                        <label
                                            htmlFor="last_name"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[2]}
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group-row">
                                    <div className="input-group-col">
                                        <label htmlFor="email" className="form-label">
                                            {content?.[1]?.[2]?.[0]?.[3]}
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="input-group-col">
                                        <label
                                            htmlFor="mobile_number"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[4]}
                                        </label>
                                        <input
                                            type="text"
                                            name="mobile_number"
                                            id="mobile_number"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div style={{height:'fit-content', width:'90%'}} className="form-group-col">
                                    <div  style={{height:'fit-content', width:'100%'}}  className="input-group-row">
                                        <label
                                            htmlFor="plus_one"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[5]}
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="plus_one"
                                            id="plus_one"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {state?.plus_one &&
                                    <div className="input-group-col" style={{textAlign:'start', marginTop:"50px", height:'fit-content', width:'90%'}}>
                                        <label
                                            htmlFor="plus_one"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[6]}
                                        </label>
                                        <input
                                            style={{width:"90%"}}
                                            type="text"
                                            name="plus_one_name"
                                            id="plus_one_name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    }
                                </div>
                                <div className="form-group-row">
                                    <div className="input-group-row">
                                        <label
                                            htmlFor="dietary_retrictions"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[7]}
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="dietary_restrictions"
                                            id="dietary_retrictions"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {state?.dietary_restrictions && (
                                    <div className="input-group-col">
                                        <label
                                            htmlFor="dietary_restrictions_summary"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[8]}
                                        </label>
                                        <textarea
                                            id="dietary_restrictions_summary"
                                            name="dietary_restrictions_summary"
                                            rows="4"
                                            cols="50"
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                )}
                                <div className="form-group-row">
                                    <div className="input-group-row">
                                        <label
                                            htmlFor="children"
                                            className="form-label"
                                        >
                                            {content?.[1]?.[2]?.[0]?.[9]}
                                        </label>
                                        <input
                                            type="checkbox"
                                            name="children"
                                            id="children"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {state.children && (
                                        <div className="input-group-row">
                                            <label
                                                htmlFor="how_many_children"
                                                className="form-label"
                                            >
                                                {content?.[1]?.[2]?.[0]?.[10]}
                                            </label>
                                            <input
                                                type="number"
                                                name="how_many_children"
                                                id="how_many_children"
                                                value={state.how_many_children}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-wrap-row">
                                    {parseInt(state.how_many_children) > 0 &&
                                        state.children &&
                                        new Array(parseInt(state.how_many_children))
                                            .fill(null)
                                            .map((_, index) => {
                                                return (
                                                    <div
                                                        style={{
                                                            marginTop: "30px",
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="input-group-col">
                                                            <label
                                                                htmlFor="childs_first_name"
                                                                className="form-label"
                                                            >
                                                                {content?.[1]?.[2]?.[0]?.[11]}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                data-index={index}
                                                                name="childs_first_name"
                                                                id="childs_first_name"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </div>
                                                        <div className="input-group-row">
                                                            <label
                                                                htmlFor="how_old_child"
                                                                className="form-label"
                                                            >
                                                                {content?.[1]?.[2]?.[0]?.[12]}
                                                            </label>
                                                            <input
                                                                type="number"
                                                                data-index={index}
                                                                name="how_old_child"
                                                                id="how_old_child"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                </div>
                            </>
                            }
                        </>
                    )}
                {rsvp === true && <>
                    {submitResult === undefined ? (
                        <>
                            <button className="rsvp-from-form-submit" type="submit">
                                Submit RSVP
                            </button>
                            <h1 style={{
                                textAlign: "center",
                                fontSize: "14px",
                                color: "red",
                            }}>
                                {error}
                            </h1></>
                    ) : submitResult === null ? (
                        <LiaSpinnerSolid
                            style={{
                                color: "#792839",
                                fontSize: "50px",
                                display: "inline-block",
                                animation: "rotate 2s infinite linear",
                            }}
                        />
                    ) : submitResult === true ? (
                        <h1 style={{textAlign: "center", fontSize: "18px", color: "#792839"}}>
                            {content?.[1]?.[4]?.[0]?.[0]}
                        </h1>
                    ) : (
                        <>
                            <button className="rsvp-from-form-submit" type="submit">
                                {content?.[1]?.[2]?.[0]?.[12]}
                            </button>
                            <h1
                                style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                    color: "red",
                                }}
                            >
                                {content?.[1]?.[3]?.[0]?.[12]}
                            </h1>
                        </>
                    )}
                </>
                }

            </form>
        </div>
    );
}


const validateForm = (formData, content) => {

    const errors = {};

    // Check required fields
    if (!formData.first_name) {
        errors.first_name = content?.[1]?.[3]?.[0]?.[1];
    }
    if (!formData.last_name) {
        errors.last_name = content?.[1]?.[3]?.[0]?.[2];
    }
    if (!formData.email) {
        errors.email = content?.[1]?.[3]?.[0]?.[3];
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        errors.email = content?.[1]?.[3]?.[0]?.[4];
    }
    if (!formData.mobile_number) {
        errors.mobile_number = content?.[1]?.[3]?.[0]?.[5];
    } else if (!/^\d{10}$/.test(formData.mobile_number)) {
        errors.mobile_number = content?.[1]?.[3]?.[0]?.[6];
    }

    // Validate dietary restrictions
    if (formData.dietary_restrictions && !formData.dietary_restrictions_summary) {
        errors.dietary_restrictions_summary = content?.[1]?.[3]?.[0]?.[7];
    }

    // Validate children details
    if (formData.children) {
        if (!formData.how_many_children || isNaN(formData.how_many_children)) {
            errors.how_many_children = content?.[1]?.[3]?.[0]?.[8];
        }

        if (!formData.children_details || formData.children_details.length === 0) {
            errors.children_details = content?.[1]?.[3]?.[0]?.[9];
        } else {
            formData.children_details.forEach((child, index) => {
                if (!child.childs_first_name) {
                    errors[`children_details[${index}].childs_first_name`] = content?.[1]?.[3]?.[0]?.[10];
                }
                if (!child.how_old_child || isNaN(child.how_old_child)) {
                    errors[`children_details[${index}].how_old_child`] = content?.[1]?.[3]?.[0]?.[11];
                }
            });
        }
    }


    return errors;
};

// Example usage:


