const localization = {
    "page-title": {
        "english": "Callan And Ancois Say I Do In:",
        "afrikaans": "Callan en Ancois trou oor"
    },
    "form": {
        "title": {
            "english": "RSVP Form",
            "afrikaans": "RSVP Vorm"
        },
        "event_details": {
            "title": {
                "english": "Event Details",
                "afrikaans": "Inligting oor die geleentheid"
            },
            "date": {
                "english": [
                    "Date:",
                    "14 December 2024"
                ],
                "afrikaans": [
                    "Datum",
                    "14 Desember 2024"
                ]
            },
            "time": {
                "english": [
                    "Time:",
                    "16:00"
                ],
                "afrikaans": ["Tyd", "16:00"]
            },
            "venue": {
                "english": [
                    "Venue:",
                    "Bell Amour, Plot 220, Bynest turnoff, Kameelfontein, Adderweg, Roodeplaat, Pretoria, 0002"
                ],
                "afrikaans": [
                    "Plek",
                    "Bell Amour, Plot 220, Bynest afrit, Kameelfontein, Adderweg, Roodeplaat, Pretoria, 0002"
                ]
            },
            "Directions": {
                "english": [
                    "Directions:",
                    "Directions"
                ],
                "afrikaans": [
                    "Aanwysings",
                    "Aanwysings"
                ]
            }
        },
        "form_inputs": {
            "english": [
                "Will you be attending our wedding?",
                "First Name",
                "Last Name",
                "Email Address",
                "Mobile Number",
                "Will You Have a Plus One",
                "Your Plus One's Full Name",
                "Any Dietary Requests?",
                "Describe Your Dietary Requests",
                "Children Under 12 Yes or No?",
                "How Many Children?",
                "Your Child's First Name",
                "Your Child's Age",
                "Submit RSVP"
            ],
            "afrikaans": [
                "Gaan jy / julle ons troue bywoon?",
                "Naam",
                "Van",
                "Epos adres",
                "Selfoon nommer",
                "Gaan jy 'n metgesel bring?",
                "Naam van jou metgesel?",
                "Spesiale dieet versoeke",
                "Besonderhede",
                "Kinders onder 12",
                "Hoeveel kinders",
                "Naam van kind",
                "Ouderdom van kind",
                "Submit RSVP"]
        },
        "form_validation_messages": {
            "english": [
                "please fix the following fields:",
                "First name is required",
                "Last name is required",
                "Email is required",
                "Email Format Is Invalid",
                "Mobile number is required",
                "Mobile number format is invalid",
                "Please provide dietary requirement details",
                "Child's first name is required",
                "Child's age must be a number",
                "Please specify how many children (must be a number) Please provide details for each child",
                "something went wrong please try again"
            ],
            "afrikaans": [
                "Vul asseblief die volgende inligting in:",
                "Naam",
                "Van",
                "Epos",
                "Vul asseblief 'n bruikbare epos adres in",
                "selfoon nommer",
                "Vul asseblief 'n bruikbare selfoon nommer in",
                "Voltooi asseblief dieet versoeke",
                "Naam van kind",
                "Ouderdom van kind moet 'n numeriese getal wees",
                "Dui asseblief aan hoeveel kinders die troue gaan bywoon",
                "Iets het verkeerd gegaan, probeer asseblief weer"

            ]
        },
        "form_success_messages": {
            "english": [
                "Thank you for RSVPING an email will be sent to your mailbox with more details around 30 september",
                "Thank you for rsvping"
            ],
            "afrikaans": [
                "Dankie vir jou RSVP. 'n Epos sal na jou posbus gestuur word met meer besonderhede rondom 30 September",
                "Dankie vir jou RSVP"
            ]
        }
    },
    "rsvp": {
        "english": ["Yes", "No"],
        "afrikaans": ["Ja", "Nee"]
    }
}

export default localization;
